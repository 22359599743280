import { motion } from 'framer-motion';
import React from 'react';

// EASINGS LUKAS EBERLEIN
// let easing = [0.175, 0.85, 0.42, 0.96]
const easing = [0.25, -0.01, 0.25, 1.02];

const TransitionSetting = {
  // initial: { x: '-100%' },
  // initial: { y: '-100%' },
  initial: {
    // y: '100px',
    opacity: 0,
  },
  exit: {
    // y: '100px',
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: easing,
    },
  },
  enter: {
    // y: '0',
    // y: '0%',
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: easing,
    },
  },
};

// const TransitionNone: any = {
//   initial: { opacity: 1 },
//   exit: {
//     opacity: 1,
//     transition: {
//       duration: 0,
//     },
//   },
//   enter: {
//     opacity: 1,
//     transition: {
//       duration: 0,
//     },
//   },
// };

interface Props {
  children: React.ReactNode;
  // pageType?: string | undefined;
}

const PageTransition = ({
  children,
}: // pageType
Props) => {
  let variants: any = TransitionSetting;
  // const isPresent = useIsPresent()

  return (
    <motion.div
      initial="initial"
      animate="enter"
      exit="exit"
      variants={variants}
      style={{ overflow: 'visible' }}
    >
      {children}
    </motion.div>
  );
};

export default PageTransition;
