import PageTransition from '../components/transition/PageTransition';
import { gql } from 'graphql-request';
import { GetStaticProps } from 'next';
import GraphQLClient from '../utils/GraphQLClient';
import { cloneDeep } from '@apollo/client/utilities';
import { lazy, Suspense } from 'react';

// import Gallery from '../components/pages/gallery/Gallery';
//
const Gallery = lazy(
  () => import('../components/pages/exhibitions/Exhibitions')
);

const galleryPage = ({ current, past, news }) => {
  // Check if already visited this page

  return (
    <PageTransition>
      <Suspense fallback={null}>
        <Gallery
          current={current}
          past={past}
          upcoming={(news && [{ ticker: news, text: '' }]) || []}
        />
      </Suspense>
      {/* {showIntro && <div>Intro</div>} */}
    </PageTransition>
  );
};

export default galleryPage;

// export const getServerSideProps: GetServerSideProps = async (props) => {
export const getStaticProps: GetStaticProps = async () => {
  const variables = {};

  const query = gql`
    query Exhibitions {
      ExhibitionItems(
        per_page: 100
        resolve_relations: "Artist.artists_relations,Product.products,Artist.artists_relations"
      ) {
        items {
          id
          name
          slug
          content {
            component
            date
            text
            location
            location_extern
            # location_custom
            # image_representative {
            #   filename
            #   name
            # }
            images {
              filename
              name
            }
            images_exhibition {
              filename
              name
            }
            # artists
            artists_relations {
              name
              # slug
              content
            }
          }
        }
      }
    }
  `;

  const queryHome = gql`
    query Index {
      HomeItems {
        items {
          content {
            news_ticker
          }
        }
      }
    }
  `;

  // news_ticker;

  const data = await GraphQLClient.request(query, variables);

  const exhibitions = data.ExhibitionItems.items;

  const { past, current } = sortExhibitions([...exhibitions]);

  const dataHome = await GraphQLClient.request(queryHome, {});

  const home = dataHome.HomeItems.items && dataHome.HomeItems.items[0];

  return {
    props: {
      exhibitions,
      events: [],
      current,
      past,
      // upcoming,
      news: home.content.news_ticker || null,
    },
  };
};

const sortExhibitions = (exhibitions) => {
  const upcoming = [];
  let current = [];
  let past = [];

  exhibitions.forEach((exhibition) => {
    if (exhibition.content.date && exhibition.content.date[0]) {
      const dateObject = exhibition.content.date[0];

      if (dateObject.component === 'Block_Date_From_Till') {
        const date_start = dateObject.start.replace(' ', 'T');
        const date_end = dateObject.end.replace(' ', 'T');

        const exhibitionStartDate = new Date(date_start);
        const exhibitionEndDate = new Date(date_end);
        const nowDate = new Date();

        if (exhibitionStartDate > nowDate) upcoming.push(exhibition);
        else if (exhibitionEndDate < nowDate) {
          const pastExhibition = exhibition;
          // delete unnescessary info
          // delete pastExhibition.images;
          // delete pastExhibition.content.text;
          // delete pastExhibition.content.image_representative;
          // delete pastExhibition.content.artists_relations;
          past.push(pastExhibition);
        } else current.push(exhibition);
      } else if (dateObject.component === 'Block_Date_time') {
        const date = dateObject.date.replace(' ', 'T');

        const exhibitionDate = new Date(date);
        // const exhibitionEndDate = new Date(date_end)
        const nowDate = new Date();

        if (exhibitionDate > nowDate) {
          // full
          const upcommingExhibition = cloneDeep(exhibition);
          delete upcommingExhibition.images_exhibition;
          upcoming.push(upcommingExhibition);
        } else {
          const currentExhibition = cloneDeep(exhibition);
          delete currentExhibition.content.images_exhibition;
          current.push(currentExhibition);
        }
      } else {
        console.log('FAILED!!! :', exhibition.name, dateObject);
      }
    } else {
      console.warn('No date found in exuibition!');
    }
  });

  if (current.length === 0) {
    // past = past.slice(1, past.length);
    // past =
    past.pop();

    current.push(cloneDeep(past[0]));
  }

  past = past
    .map((post) => {
      const pastExhibition = post;
      delete pastExhibition.images;
      delete pastExhibition.content.text;
      delete pastExhibition.content.image_representative;
      delete pastExhibition.content.artists_relations;

      return pastExhibition;
    })
    .sort((a, b) => {
      const curDate = a.content.date[0].end;
      const nxtDate = b.content.date[0].end;

      if (curDate > nxtDate) {
        return -1;
      } else if (curDate < nxtDate) {
        return +1;
      } else {
        return 0;
      }
    });

  return { past, current, upcoming };
};
